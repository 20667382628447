import "./table.scss";

const List = () => {
  const submit = () => {
    const element = document.createElement("a")
    const file = new Blob ([document.querySelector(".scenario select").value,(", \n"),
                            document.querySelector(".region select").value,(", \n"),
                            document.querySelector(".goals select").value,(", \n"),
                            document.querySelector(".time input").value,(", \n"),
                            document.querySelector(".duration input").value,(", \n"),
                            document.querySelector(".date input").value],{type:"selectMenu.value"});
                            // document.querySelector(".constraints select").value,(", \n"),
                            // document.querySelector(".filetype select").value,(", \n"),
                            // document.querySelector(".type select").value,(", \n"), 

    element.href = URL.createObjectURL(file)
    element.download = document.querySelector(".file-name input").value;
    document.body.appendChild(element);
    element.click()
  }



  return (
          <div class = "input">
                  <div class="option region">
                <label>Select Regions</label>
                <div class="select-menu">
                  <select className="drop">
                    <option value="wr">WR: Wakefield Road</option>
                  </select>
                </div>
              </div>
              <div class="option constraints">
              <label>Network Constraints</label>
              <div class="select-menu">
                <select className="drop">
                  <option value="1">Accident</option>
                  <option value="2">Roadworks</option>
                  <option value="3">other type of blockage</option>
                </select>
              </div>
            </div>

            <div class="option goals">
              <label>Specify Goals</label>
              <div class="select-menu">
                <select className="drop">
                  <option value="1">Increase traffic flow towards the match going west from Wakefield Road</option>
                  <option value="2">Increase traffic flow East to exit the region along the corridor </option>
                  <option value="3">Moving Traffic away from Location</option>
                  <option value="4">Moving traffic to the Location</option>
                  <option value="5">Hold traffic back</option>
                </select>
              </div>
            </div>

            <div class="option filetype">
              <label>Output FileType</label>
              <div class="select-menu">
                <select className="drop">
                  <option >XML</option>
                  <option >Geo Json</option>
                  <option >Text</option>
                  <option >CSV</option>
                  <option >XLS</option>
                </select>
              </div>
            </div>

            <div class="option type">
              <label>Output Type</label>
              <div class="select-menu">
                <select className="drop">
                  <option >signal green and red</option>
                  <option >Link saturation values</option>
                  <option >Number of vehicles queueing</option>
                  <option >Link speed</option>
                </select>
              </div>
            </div>

            <div class="option scenario">
              <label>Select Senarios</label>
              <div class="select-menu">
                <select className="drop">
                <option value="0"></option>
                  <option value ="1">Before Football match Saturday 21/09/2022</option>
                  <option value ="1">After Football match Saturday 21/09/2022</option>
                </select>
              </div>
            </div>
            
            <div class ="time">
              <div class = "select-input">
              <label>Enter start-Time</label>
              <input type="time" id="start-time" name="start-time" placeholder="hh-mm-ss" className="drop"/>
              </div>
            </div>

            <div class ="duration">
              <div class = " select-input" >
              <label>Enter Duration</label>
             
              <input type="number" id="duration" name="start-time" placeholder="Enter 5, 10, 30, 60" className="drop"/>
            
              </div>
            </div>

            <div class ="date">
              <div class = "select-input">
              <label>Enter Date</label>
              <input type="date" id="date" name="Date" placeholder="dd-mm-yyyy" className="drop"/>
              </div>
            </div>

            <div class="file-options">
              <div class="option file-name">
                <label>Save as scenario</label>
                <input type="text" id = "filename" spellcheck="false" placeholder="Enter file name" className="drop"/>
                
              </div>
              <div class="option save-as">
                
                </div>
              </div>
            
            
          <div/>
          
        <div/>
        <button class="save-btn" type="button" onClick={submit}>Submit</button>
        <button class="save-btn" id="simulate" type="button" >Simulate</button>
        <button class="save-btn" id = "plan" type="button">Plan & Simulate</button>
        </div>

        
  );

};
export default List;
